import { Field } from 'formik';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import SaveIcon from '@material-ui/icons/GetApp';
import { useMemo, memo, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Link,
  Typography,
} from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { formatDate } from 'utils/format.util';
import { required } from 'utils/validation.util';
import { EMPTY_MAP, Format } from 'app/app.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';

const WellboreFilesTable = ({
  updateFile,
  deleteFile,
  downloadFile,
  noItemsMessage,
  documents = EMPTY_MAP,
  documentCategoriesById = EMPTY_MAP,
  downloadProjectFile,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 5,
        Header: 'Name',
        accessor: (document) => document.get('fullName', null),
        Cell: ({ row, value }) => {
          return !row.original.get('projectId') ? (
            <Field
              name="name"
              validate={required}
              component={TableRowTextFieldFormik}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {row.original.get('extension')}
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Typography variant="body2">{value}</Typography>
          );
        },
      },
      {
        xs: 3,
        Header: 'Category',
        accessor: (document) => document.get('categoryName', null),
        Cell: ({ row, value }) => {
          return !row.original.get('projectId') ? (
            <Field
              select
              name="category"
              validate={required}
              component={TableRowTextFieldFormik}
            >
              {documentCategoriesById.valueSeq().map((category) => (
                <MenuItem key={category.get('id')} value={category.get('id')}>
                  {category.get('name')}
                </MenuItem>
              ))}
            </Field>
          ) : (
            <Typography variant="body2">{value}</Typography>
          );
        },
      },
      {
        xs: 2,
        Header: 'Source',
        accessor: (document) => document.get('projectId', null),
        Cell: ({ row }) => {
          return row.original.get('projectId') ? (
            <Link href={`/projects/${row.original.get('projectId')}/`}>
              {`Project ${row.original.get('projectId')}`}
            </Link>
          ) : null;
        },
      },
      {
        xs: 2,
        Header: 'Uploaded',
        accessor: (document) =>
          formatDate(document.get('created'), Format.time),
      },
      {
        id: 'actions',
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={2} />,
        Footer: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => (
          <TableRowActionsCell minItems={2}>
            {!row.original.get('projectId') && (
              <IconButton
                title="Delete"
                onClick={() => deleteFile(row.original.get('fileId'))}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
            {!row.original.get('projectId') ? (
              <IconButton
                title="Download"
                onClick={() =>
                  downloadFile(row.original.get('fileId'), row.original)
                }
              >
                <SaveIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                title="Download"
                onClick={() =>
                  downloadProjectFile(
                    row.original.get('projectId'),
                    row.original.get('fileId'),
                    row.original,
                  )
                }
              >
                <SaveIcon fontSize="small" />
              </IconButton>
            )}
          </TableRowActionsCell>
        ),
      },
    ],
    [deleteFile, downloadFile, documentCategoriesById, downloadProjectFile],
  );

  const renderTableRowComponent = useCallback(
    (props) => <EditableTableRowFormik {...props} onSubmit={updateFile} />,
    [updateFile],
  );

  return (
    <Table
      columns={columns}
      items={documents}
      useGlobalFilter={false}
      noItemsMessage={noItemsMessage}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

WellboreFilesTable.propTypes = {
  documents: PropTypes.instanceOf(Collection),
};

export default compose(memo)(WellboreFilesTable);
