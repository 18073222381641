import moment from 'moment';
import last from 'lodash/last';
import initial from 'lodash/initial';
import { fromJS, Map } from 'immutable';

export const initialDocument = () => {
  return {
    id: null,
    name: '',
    category: 1,
    extension: null,
    field: { id: null },
    created: null,
    modified: null,
  };
};

export const toFileFromDTO = ({
  fieldFileId,
  name,
  category,
  fieldId,
  extension,
  created,
  modified,
  taskId,
  fullName,
  createdByUserId,
  categoryName,
  editUrl,
  comments,
  fileId,
  projectId,
  wellboreId,
}) => {
  return fromJS({
    id: fieldFileId,
    name: name,
    category: category,
    extension: extension,
    created: moment(created),
    modified: modified,
    taskId: taskId,
    fullName: fullName,
    createdByUserId,
    fileId,
    categoryName,
    editUrl,
    comments,
    fieldId,
    fieldFileId,
    projectId,
    wellboreId,
  });
};

const initialMetaFromFilename = ({ name }, initialCategory) => {
  const parts = name.split('.');

  return Map({
    name: initial(parts).join('.'),
    category: initialCategory.id,
    extension: `.${last(parts)}`,
  });
};

const documentMappers = {
  Document: {
    initial: initialDocument,
    from: toFileFromDTO,
    Meta: {
      initial: initialMetaFromFilename,
    },
  },
  toFileFromDTO,
  initialDocument,
};

export default documentMappers;
