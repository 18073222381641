import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import RadioGroupFieldFormik from 'app/components/form/formik/RadioGroupFieldFormik';
import {
  BOOLEAN_DATA_VALUES_ENUM,
  IntegrityForm,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { useRef } from 'react';
import { debounce } from 'lodash';

const BooleanRuleDataFormComponent = ({ classes, updateValue }) => {
  const { values } = useFormikContext();
  const isFirstRender = useRef(true);
  const previousValue = useRef(values[IntegrityForm.VALUE]);

  const options = [BOOLEAN_DATA_VALUES_ENUM.YES, BOOLEAN_DATA_VALUES_ENUM.NO];

  const debouncedUpdateValue = useRef(
    debounce((newValues) => {
      updateValue(newValues);
    }, 500),
  ).current;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (previousValue.current !== values[IntegrityForm.VALUE]) {
      previousValue.current = values[IntegrityForm.VALUE];
      debouncedUpdateValue(values);
    }
    // eslint-disable-next-line
  }, [values]);

  return (
    <Grid item xs={6}>
      <Field name={IntegrityForm.VALUE}>
        {({ form, ...formik }) => {
          formik.field.onChange = (e) => {
            form.setFieldValue(IntegrityForm.VALUE, Number(e.target.value));
          };
          return (
            <RadioGroupFieldFormik
              classes={classes}
              options={options}
              form={form}
              row={false}
              {...formik}
            />
          );
        }}
      </Field>
    </Grid>
  );
};

const styles = () => ({
  group: {
    display: 'flow',
  },
});

export default withStyles(styles)(BooleanRuleDataFormComponent);
