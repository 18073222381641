import React, { useEffect } from 'react';
import { IntegrityForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { Grid } from '@material-ui/core';
import { useFormikContext, Field } from 'formik';
import withStyles from '@material-ui/styles/withStyles';
// import { debounce } from 'lodash';
// import { useCallback } from 'react';

const GeographyRuleDataFormComponent = ({
  classes,
  // integrity,
  // updateValue,
}) => {
  const { values, setFieldValue } = useFormikContext();

  // const debouncedUpdateValue = useCallback(
  //   debounce((_newValues) => {
  //     // TODO: set the data according the API
  //     // updateValue(newValues);
  //   }, 500),
  //   [],
  // );

  // useEffect(() => {
  //   if (integrity.value !== values.value) debouncedUpdateValue(values);
  //   // eslint-disable-next-line
  // }, [values]);

  useEffect(() => {
    setFieldValue(`${IntegrityForm.VALUE}`, {
      lat: values.value.x,
      long: values.value.y,
    });
    // eslint-disable-next-line
  }, [setFieldValue]);

  return (
    <Grid className={classes.gridStyle}>
      <Grid item xs={6} container>
        <Field
          type="number"
          placeholder="Latitude"
          component={TextFieldFormik}
          name={`${IntegrityForm.VALUE}.lat`}
          className={classes.fieldStyle}
        />
      </Grid>
      <Grid item xs={6} container>
        <Field
          type="number"
          placeholder="Longitude"
          component={TextFieldFormik}
          name={`${IntegrityForm.VALUE}.long`}
        />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  gridStyle: {
    display: 'flex',
  },
  fieldStyle: {
    marginRight: theme.spacing(0.5),
  },
});

export default withStyles(styles)(GeographyRuleDataFormComponent);
