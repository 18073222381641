import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import { FIELDS_ACTIONS } from 'features/fields/fields.constants';
import documentMappers from 'features/fields/fieldDocuments.mappers';

const initialState = EMPTY_MAP;

const fieldDocumentsReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case FIELDS_ACTIONS.GET_FIELD_DOCUMENT: {
      if (error) return state;

      const { payload } = action;

      return state.set(
        payload.fieldFileId.toString(),
        documentMappers.Document.from(payload),
      );
    }

    case FIELDS_ACTIONS.DELETE_FIELD_DOCUMENT: {
      if (error) return state;

      const { fieldFileId } = action;

      return state.delete(fieldFileId.toString());
    }

    case FIELDS_ACTIONS.GET_FIELD_DOCUMENTS: {
      if (error) return state;

      return OrderedMap(
        payload.map((file) => [
          file.fileId.toString(),
          documentMappers.Document.from(file),
        ]),
      );
    }

    default:
      return state;
  }
};

export default fieldDocumentsReducer;
