import { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchWellboreIntData } from 'features/wells/wellbore.actions';

import { selectWelboreIntData } from './wellbore.selectors';
import WellBoreSchematic from 'features/testing/wellLogBasic/WellBoreSchematic';

const SchematicContainer = ({
  intData,
  dispatchGetWellboreIndtData,
  fieldId,
  wellboreId,
}) => {
  const { data, error, isFetching } = intData;
  const hasIds = fieldId && wellboreId;

  useEffect(() => {
    if (hasIds) {
      dispatchGetWellboreIndtData(wellboreId);
    }
  }, [dispatchGetWellboreIndtData, hasIds, wellboreId]);

  if (isFetching === true) {
    //will display proper component
    return 'Loading....';
  }
  if (error) {
    //will display proper component
    return 'Error ocured.';
  }
  return <WellBoreSchematic data={data} />;
};

export default connect(
  (state) => ({
    intData: selectWelboreIntData(state),
  }),
  {
    dispatchGetWellboreIndtData: fetchWellboreIntData,
  },
)(SchematicContainer);
